import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "analytics-nav-menu__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "analytics-nav-menu__group-title" }, "Instructors", -1)),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/skills-tracking",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Skills Tracking")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/number-of-next-level",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Number of students moved to the next level ")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/average-time-student-level",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("Average time student is in the level")
      ])),
      _: 1
    }),
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "analytics-nav-menu__group-title" }, "Students", -1)),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/students/fast-learners",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("Fast learners")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/students/slow-movers",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" Slow movers ")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/students/students-moved-up-to-next-level",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [
        _createTextVNode("Students moved up to next level")
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/analytics/students/time-spent-in-program",
      class: "analytics-nav-menu__menu-item"
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode("Time spent in the program")
      ])),
      _: 1
    })
  ]))
}