
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AnalyticsNavMenu',
  data () {
    return {}
  },
  components: {},
  methods: {}
})
